@font-face {
  font-family: Nexa;
  src: url('../../assets/fonts/Nexa-Heavy.ttf');
}
@font-face {
  font-family: Lato;
  src: url('../../assets/fonts/Lato-Regular.ttf');
}
@font-face {
  font-family: Anton;
  src: url('../../assets/fonts/Anton-Regular.ttf');
}
@font-face {
  font-family: Fake;
  src: url('../../assets/fonts/Fake-Serif.ttf');
}
header{
  position: absolute;
  color: white;
  z-index: 10;
  width: 100%;
}
.header-background {
  padding-top: 80px;
  background-image: url('/src/assets/images/background-top.png');
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.logo img {
  max-width: 150px;
  height: auto;
}

.menu ul {
  list-style: none;
  display: flex;
  margin-top: 13px;
}
.landing_h2{
  font-family: Anton;
  font-size: 8rem;
  font-weight: 400;
  line-height: 124.8px;
  text-align: left;
  text-transform: uppercase;
  color: #FFFFFF;
}
.container_parts_h2{
  display: flex;
  width: 100%;
}
.landing_part2_h2{
  font-family: Anton;
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 47.2px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 10px;
}
.landing_part3_h2{
  font-family: Anton;
  font-size: 6rem;
  font-weight: 400;
  line-height: 84.4px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.subtitle_h2 {
  color: #001489;
  font-family: Anton;
  font-size: 33px;
  font-weight: 400;
  line-height: 29.4px;
  text-shadow: -2px 2px 0px rgba(255, 255, 255, 1);
}
.landing_h3{
  color: #001489;
  font-family: Nexa;
  font-size: 2.7rem;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  text-transform: uppercase;
}
.landing_h5{
  color: #001489;
  font-family: Nexa;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.page_text{
  color: #062A4E;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-right: 20px;
}
.product_text_top{
  color: #062A4E;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-right: 4px;
}
.text_content{
  color: #062A4E;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-right: 17%;
}
.product_text{
  color: #062A4E;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-right: 20px;
}
.product_min_height{  
  min-height: 130px;
}
.product_img{
  width: 100%;
}
.discover{
  color: #001489;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  text-transform: uppercase;
  border-radius: 999px;
  display: flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: #6DCFF6;
  box-shadow: 6px 8px 0px 0px #001489;
  width: 200px;
}
.discover a{
  color: #001489;
  text-decoration: none;
}
.wheretobuy{
  color: #001489;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  text-transform: uppercase;
  border-radius: 999px;
  display: flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: #6DCFF6;
  box-shadow: 6px 8px 0px 0px #001489;
  width: 300px;
}
.landing_h6{
  color: #001489;
  font-family: Nexa;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.wheretobuy a {
  text-decoration: none;
  color: #001489;
}
.wherebuttom{
  display: flex;
  padding: 6px 30px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 16px;
  font-weight: bold;
  align-self: stretch;
  border-radius: 999px;
  background: #6DCFF6;
  box-shadow: 4px 4px 0px 0px #001489;
}
.wherebuttomdisable{
  display: flex;
  padding: 7px 30px;
  justify-content: center;
  align-items: center;  
  font-size: 12px;
  gap: 16px;
  font-weight: bold;
  align-self: stretch;
  border-radius: 999px;
  background: #C2C2C2;
}
.wherebuttom a{
  color: #001489;
  text-decoration: none;
}
.logostore{
  width: 150px;
  height: 60px;
  align-items: center;
}
.logostore img{
  max-width: 150px;
}
.button-modal{
  display: block;
  border: none;
  cursor: pointer;
  display: block;
  height: 40px;
  position: absolute;
  right: -10px;
  top: -40px;
  width: 40px;
  z-index: 2;
  background: 0;
  color: #FFF;
}
.stores{
  display: flex;
  justify-content: space-between;
  @media (max-width: 940px){
    
  overflow-y: scroll;
  display: block;
  height: 65vh;
}
}
.modal-base{
  background-color: #FFF;
  font-weight: 600;
  left: 50%;
  line-height: 120%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 18px;
  min-width: 900px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 10px 10px 0px 0px #E8927C;
}
.text-white{
  color: #FFFFFF !important;
}
.menu li {
  margin-right: 20px;
  color: #001489;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  font-family: 'Lato';
}
.menu li a{
  text-decoration: none;
  font-size: 1.3rem;
  color: #FFFFFF;
}
.menu li a:hover{
  color: #CCC;
}
.social{
  color: #001489;
}
.footer-top-bg{
  background-image: url('/src/assets/images/bg-top-footer.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.footer-bg{
  background-image: url('/src/assets/images/bg-footer.png');
  height: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.bg-blue{
  background-image: url('/src/assets/images/bg-blue.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
  margin: 0 auto;
  padding: 20px 0;
}
.footer{
  background-color: #001489;
  color: #FFFFFF;
  font-size: 1em;
  font-weight: 400;
  padding: 10px 0;
}
.terms{
  text-align: left;
  padding-right: 3rem!important;
  padding-left: 3rem !important;
}
.terms a{
  color: #FFF;
}
.elementor-social-icon {
  --e-social-icon-icon-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #FFF;
  text-decoration: none;
}
.elementor-icon {
  display: inline-block;
  border: #FFFFFF 2pt solid;
  background-color: #FFFFFF;
  border-radius: 50%;
  transition: all .3s;
  font-size: 15px;
  text-align: center;
  padding: 8px 8px 4px 8px;
  width: 40px;
  color: #001489;
}
.elementor-icon a:hover{
  color: #001489;
}
.icons-social {
  margin-top: 100px;
  width: 240px;
  position: absolute;
  right: 0;
  margin-right: 2.5rem;
}
.icons-social .col {
  text-align: center;
}
.copyright{
  text-align: right;
  padding-right: 3rem!important;
  padding-left: 3rem !important;
}
.text-footer{
  color: #062A4E;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;  
  max-width: 650px;
  margin-top: 1.5rem!important;
}
.mt-tf{
  margin-top: 3rem!important;
}
.menu-toggle {
  display: none;
  border: 0;
  background: none;
}

.nav-hamburger {
  cursor: pointer;
  text-align: center;
  width: 30px;
}
.hamburger .line {
  background: #36c95f;
  display: block;
  height: 3px;
  border-radius: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  transition: all .3s ease-in-out;
}
.menu-toggle {
  display: none;
}

.menu-toggle .menu-icon {
  display: block;
  position: relative;
  width: 30px;
  height: 3px;
  background-color: #001489;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.menu-toggle .menu-icon:before,
.menu-toggle .menu-icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #001489;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.menu-toggle .menu-icon:before {
  top: -8px;
}

.menu-toggle .menu-icon:after {
  top: 8px;
}
.texttop {
  max-width: 500px;
  margin: auto;
  padding: 1rem 0 0 0;
}
@media (max-width: 940px){
  .modal-base{
    font-size: 18px;
    width: 80%;
    min-width: 350px;
    max-height: 90vh;
  }
  .logostore {
    width: 100%;
    align-items: center;
    position: relative;
  }
  .logostore img {
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .bt{
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 768px) {
  .landing_h2{
    font-size: 7.5rem;
    font-weight: 400;
    line-height: 124.8px;
  }
  .landing_part2_h2{
    font-family: Anton;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 47.2px;
    margin: 0 10px;
  }
  .landing_part3_h2{
    font-size: 6rem;
    font-weight: 400;
    line-height: 84.4px;
  }
  .header-background {
    justify-content: flex-start;
    padding-top: 120px;
    height: auto;
  }
  .subtitle_h2{
    font-size: 2.2rem;
  }
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }
  .product_text{
    font-size: 1.5rem;
  }
  .text_content{
    font-size: 1.5rem;
    padding-right: 20px;
  }
  .menu ul {
    flex-direction: column;
  }
  .menu li {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .landing_h3{
    font-size: 38px;
  }
  .landing_h5{
    font-size: 20px;
  }
  .header {
    font-size: calc(40px + 2vmin);
    padding: 0 0 10px 0;
  }
  .terms, .copyright{
    text-align: center;
    padding-right: 1rem!important;
    padding-left: 1rem !important;
  }
  .icons-social {
    position: relative;
    width: auto;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
  }
  .text-footer{
    font-family: 'Lato';
    color: #062A4E;
    font-size: 1.7em;
    text-align: center;
    margin-top: 0!important;
  }
  .footer-bg{
    height: 300px;
  }
  .bg-blue{
    background-size: auto 100%;
  }
  .mt-tf{
      margin-top: 4.5rem!important;
  }
  .menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .header.menu-open .menu {
    max-height: 300px;
  }
  header.header.menu-open  {
    background-color: #001489;
    height: 100vh;
  } 
  header.header.menu-open .wheretobuy  {
    position: absolute;
    bottom: 50px;
    width: 92%;
    height: 70px;
    transition: all .3s ease-in-out;
  }
  header.header.menu-open .menu-toggle .menu-icon:before,
  header.header.menu-open .menu-toggle .menu-icon:after {
    content: "";
    background-color: #FFF;
  }

  .menu-toggle {
    display: block;
    border: none;
    cursor: pointer;
    display: block;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 20px;
    width: 40px;
    z-index: 2;
  }
  .menu-toggle.open .menu-icon {
    background-color: transparent;
  }

  .menu-toggle.open .menu-icon:before {
    transform: rotate(45deg);
    top: 0;
  }

  .menu-toggle.open .menu-icon:after {
    transform: rotate(-45deg);
    top: 0;
  }
  .menu ul {
    flex-direction: column;
    padding: 0;
  }
  .menu li a{
    color: #FFF;    
    font-size: 1.6rem;
    font-weight: 700;
  }
}
@media (max-width: 391px) {
  .landing_h2{
    font-size: 6.5rem;
    font-weight: 400;
    line-height: 124.8px;
  }
  .landing_part2_h2{
    font-family: Anton;
    font-size: 2rem;
    font-weight: 400;
    line-height: 47.2px;
    margin: 0 10px;
  }
  .landing_part3_h2{
    font-size: 4.8rem;
    font-weight: 400;
    line-height: 84.4px;
  }
  .subtitle_h2 {
    font-size: 2rem;
}
}
@media (min-width: 768px) and (max-width: 1024px) {  
  .texttop {
    width: 100%;
    margin: auto;
  }
  .text-footer{
    color: #062A4E;
    font-size: 1.3em;
    margin-top: 1.5rem!important;
  }
  .landing_h3{
    font-size: 42px;
  }
  .landing_h5{
    font-size: 24px;
  }
}